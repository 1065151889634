<template>
    <div>
        <Table
            :isLoading="isLoading"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isPagination="true"
            :isFocusRow="true"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel', 'zooming']"
            :sortDefault="{ sort: 'asc', sortBy: 'name' }"
            class="table"
            :isFixedHeader="true"
            :descriptions="descriptions"
        >
            <template v-slot:flag="{ isHeader, row }">
                <div v-if="isHeader"></div>
                <template v-if="!isHeader">
                    <div :class="`tag ${row?.flag}-flag-icon`" />
                </template>
            </template>
            <template v-slot:insLink="{ isHeader, row }">
                <template v-if="!isHeader">
                    <div
                        :class="
                            $isMobile()
                                ? 'mobile-insurance-btn'
                                : 'detail-link-icon'
                        "
                        @click.stop="goSearchInsurance(row)"
                    />
                </template>
            </template>
            <template v-slot:remark="{ isHeader, row }">
                <template v-if="!isHeader">
                    <div
                        :class="
                            $isMobile() ? 'mobile-remark-btn' : 'remark-icon'
                        "
                        @click.stop="openRemark(row)"
                    />
                </template>
            </template>
            <template v-slot:supplierInfo="{ isHeader, row }">
                <div
                    v-if="!isHeader"
                    :class="{
                        'supplier-info-btn': $isMobile(),
                        'supplier-info-icon': !$isMobile(),
                        disabled: row.fromSupplierDataStatus === null
                    }"
                    @click.stop="
                        showSupplierInfoModal(
                            row.id,
                            row.fromSupplierDataStatus
                        )
                    "
                />
            </template>
            <!-- 保單類型 -->
            <template v-slot:insType="{ isHeader, row }">
                <div v-if="!isHeader" class="type-icon flex">
                    <div
                        class="life-ins-icon"
                        :class="row.hasInsTypes?.includes(1) ? '' : 'disabled'"
                    />
                    <div
                        class="property-ins-icon"
                        :class="
                            row.hasInsTypes?.includes(100) ? '' : 'disabled'
                        "
                    />
                    <div
                        class="group-ins-icon"
                        :class="
                            row.hasInsTypes?.includes(220) ? '' : 'disabled'
                        "
                    />
                </div>
            </template>
            <!-- 電話 -->
            <template v-slot:phone="{ isHeader, row }">
                <div v-if="!isHeader" class="phone-content">
                    <a
                        v-if="row.mobile"
                        class="phone"
                        :href="`tel:${row.mobile}`"
                        @click.stop
                    >
                        {{ row.originalMobile }}
                        <span class="phone-icon" />
                    </a>
                    <a
                        v-if="row.TEL"
                        class="phone"
                        :href="`tel:${row.TEL}`"
                        @click.stop
                    >
                        {{ row.originalTEL }}
                        <span class="phone-icon" />
                    </a>
                    <a v-if="!row.mobile && !row.TEL">-</a>
                </div>
            </template>
            <!-- 地址 -->
            <template v-slot:address="{ isHeader, row }">
                <template v-if="!isHeader">
                    <a
                        class="address flex"
                        :href="this.transAddressLink(row?.address)"
                        target="_blank"
                        @click.stop
                    >
                        <div v-if="row?.address" class="address-hover-icon" />
                        <div v-if="row?.address" class="address-normal-icon" />
                        <p>{{ row.address || '-' }}</p>
                    </a>
                </template>
            </template>
        </Table>
        <Modal v-model="editModal.isShow" title="備註">
            <template #modal-content>
                <textarea
                    v-if="editModal.isEditing"
                    v-model="editModal.editText"
                    ref="editInput"
                />
                <div v-else>{{ editModal.rowData?.remark }}</div>
            </template>
            <template #modal-bottom>
                <Button
                    buttonWord="取消"
                    buttonStyle="grey"
                    @click="this.editModal.isShow = false"
                />
                <Button
                    v-if="editModal.isEditing && !isRemarkUploading"
                    buttonWord="儲存"
                    buttonStyle="blue"
                    @click="saveRemark"
                />
                <Button
                    v-else-if="editModal.isEditing && isRemarkUploading"
                    iconClass="process-loading-icon"
                    buttonStyle="blue"
                    @click="saveRemark"
                />
                <Button
                    v-else
                    buttonWord="編輯"
                    buttonStyle="blue"
                    @click="editRemark"
                />
            </template>
        </Modal>
        <SuccessModal v-model="isRemarkSaveSuccess" message="儲存成功" />
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import {
    customerColumns,
    customerDescriptions
} from '@/assets/javascripts/tia/setting'
import SuccessModal from '@/containers/SuccessModal.vue'
import { putCustomerInfoApi } from '@/assets/javascripts/api'

export default {
    name: 'CustomerTable',
    components: {
        Table,
        Modal,
        Button,
        SuccessModal
    },
    props: {
        listData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        eventList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    emits: ['showsuppliersyncmodal'],
    methods: {
        transformData: function (rows) {
            return rows.map((row) => {
                row.flag = ''
                if (row?.birthdayFlag === true) row.flag = 'birthday'
                if (row?.insAgeFlag === true) row.flag = 'insage'

                row.flagTrans = ''
                if (row?.birthdayFlag === true) row.flagTrans = '本日生日'
                if (row?.insAgeFlag === true) row.flagTrans = '即將+1歲'

                row.phone = row.mobile ? row.mobile : ''
                row.phone += row.mobile && row.TEL ? ' / ' : ''
                row.phone += row.TEL ? row.TEL : ''

                row.remark = row.note ? row.note : ''

                if (row.hasInsTypes) {
                    row.insType = row.hasInsTypes.includes(1) ? '壽' : ''
                    row.insType += row.hasInsTypes.includes(100) ? '產' : ''
                    row.insType += row.hasInsTypes.includes(220) ? '團' : ''
                }
                return row
            })
        },
        goSearchInsurance: function (rowData) {
            let insurancePayload = _.cloneDeep(this.payload)
            //移除本來客戶查詢所需參數
            delete insurancePayload.dateEnd
            delete insurancePayload.dateStart
            delete insurancePayload.birthdayType

            //增加保單查詢所需參數
            insurancePayload.customerName = rowData.name
            insurancePayload.receiveDateStart = null
            insurancePayload.receiveDateEnd = null
            insurancePayload.customerCode = rowData.customerCode
            this.$setGaEvent('goSearchInsurance', 'click-Icon')
            this.$crossPageFilter('TiaInsurance', insurancePayload)
        },
        transAddressLink: function (str) {
            if (!str) return
            else {
                const googleUrl = 'https://www.google.com.tw/maps/place/'
                let address = str.slice(0, str.lastIndexOf('號') + 1)
                if (
                    -1 <
                    address.split('').reverse().join('').lastIndexOf('之') <
                    5
                )
                    return googleUrl + address.replace('之', '-')
            }
        },
        getGa: function (val) {
            if (val.title === 'phone') {
                this.$setGaEvent('callCustomer', 'click-Link')
            }
            if (val.title === 'address')
                this.$setGaEvent('positionAddress', 'click-Link')
        },
        showSupplierInfoModal: function (val, status) {
            if (status === null) return
            this.$emit('show-supplier-sync-modal', val)
            this.$setGaEvent('showSupplierSyncModal', 'click-Icon') //GA
        },
        openRemark: function (row) {
            this.editModal.isShow = true
            this.editModal.rowData = row
            const tmp = this.tmpRemark[row.customerCode]
            this.editModal.editText = _.isString(tmp) ? tmp : row.remark
            this.editModal.isEditing =
                !(row.remark || _.isString(tmp)) ||
                this.editModal.editText === ''
            this.$nextTick(() => this.$refs.editInput?.focus())
        },
        editRemark: function () {
            this.editModal.editText = this.editModal.rowData?.remark
            this.editModal.isEditing = true
            this.$nextTick(() => this.$refs.editInput?.focus())
        },
        saveRemark: async function () {
            this.isRemarkUploading = true

            const rowData = this.editModal.rowData
            try {
                const payload = {
                    manCode: rowData.manCode,
                    customerCode: rowData.customerCode,
                    note: this.editModal.editText
                }
                await putCustomerInfoApi(payload)

                // save to tmpRemark
                this.tmpRemark[rowData.customerCode] = this.editModal.editText

                this.editModal.rowData.remark = this.editModal.editText

                // reset editModal
                this.editModal.isEditing = false
                this.editModal.editText = ''

                this.isRemarkSaveSuccess = true
            } catch (e) {
                this.$showErrorMessage(
                    '儲存時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.isRemarkUploading = false
            }
        }
    },
    computed: {
        //提供後端辨識身份用
        table_addCustomerCodes: function () {
            let tableData = this.listData
            for (let listCode in tableData) {
                if (tableData[listCode]) {
                    tableData[listCode].customerCode = +listCode
                }
            }
            return tableData
        },
        tableShowData: function () {
            let tableData = Object.values(this.table_addCustomerCodes)
            if (tableData.length === 0) return []
            let dataset = _.cloneDeep(tableData)
            dataset = this.transformData(dataset)
            return dataset
        },
        dummyColum: function () {
            const obj = {}
            Object.keys(this.tableColumnConfig).forEach((col) => {
                obj[col] = ' '
            })
            return obj
        },
        fileName: function () {
            const Today = new Date()
            const year = Today.getFullYear()
            const month = Today.getMonth() + 1
            const day = Today.getDate()
            const account = '_' + this.filterLog?.manCodes?.label

            return `${year}${month > 9 ? month : '0' + month}${
                day > 9 ? day : '0' + day
            }${account}_客戶清單`
        },
        excelFilterLog: function () {
            const log = _.cloneDeep(this.filterLog)

            if (!log.birthdayType?.value) {
                delete log.dateStart
                delete log.dateEnd
            }
            if (log.dateStart) {
                log.dateStart.title =
                    log.birthdayType.label + log.dateStart.title
                log.dateStart.label = log.dateStart?.label.slice(5)
            }
            if (log.dateEnd) {
                log.dateEnd.title = log.birthdayType.label + log.dateEnd.title
                log.dateEnd.label = log.dateEnd?.label.slice(5)
            }
            return log
        },
        excelConfig: function () {
            return {
                fileName: this.fileName,
                filters: this.excelFilterLog
            }
        }
    },
    data() {
        return {
            tableColumnConfig: customerColumns,
            editModal: {
                isShow: false,
                isEditing: false,
                editText: '',
                rowData: null
            },
            tmpRemark: {},
            isRemarkUploading: false,
            isRemarkSaveSuccess: false,
            descriptions: customerDescriptions
        }
    },
    watch: {
        tableShowData: {
            handler: function () {
                this.$reloadTips()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.info-icon {
    height: 14px;
    width: 14px;
    margin-left: 5px;
}
:deep(.flag) {
    align-items: flex-start;
    position: relative;
    .tag {
        position: absolute;
        top: -2px;
        left: -20px;
        height: 40px;
        width: 16px;
    }
}

:deep(.ins-link) {
    position: relative;
    .detail-link-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

:deep(.remark) {
    position: relative;
    .remark-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
}

:deep(.supplier-info) {
    .supplier-info-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

a.address {
    align-items: center;
    text-align: start;
    .address-normal-icon {
        display: inline-block;
        height: 25px;
        width: 20px;
        margin-right: 5px;
        @media screen and (max-width: 576px) {
            display: none;
        }
    }
    &:hover {
        .address-normal-icon {
            background-image: url('~@/assets/images/btn_address_hover.webp');
        }
    }
    .address-hover-icon {
        display: none;
        height: 25px;
        width: 20px;
        margin-right: 5px;
        @media screen and (max-width: 576px) {
            display: inline-block;
        }
    }
    p {
        margin-block: 0;
        text-align: left;
        width: 100%;
        display: inline-block;
    }
}

:deep(.phone-content) {
    a.phone {
        line-height: 25px;
        color: $primary-blue;
        text-decoration: underline;
        display: block;
        ~ .phone-icon {
            display: none;
            @media screen and (max-width: 576px) {
                display: block;
                margin-left: 3px;
                height: 20px;
                width: 20px;
            }
        }
    }
}

:deep(.type-icon) {
    > div:not(:last-child) {
        margin-right: 5px;
    }
    .life-ins-icon,
    .property-ins-icon,
    .group-ins-icon {
        width: 20px;
        height: 20px;
    }
}

.table {
    width: 100%;
    @media screen and (min-width: 577px) and (max-width: 856px) {
        :deep(table) {
            min-width: 797px;
        }
    }
}
.table-wrapper {
    @media screen and (max-width: 576px) {
        :deep(.row-container .row.body) {
            position: relative;

            &.focus {
                position: relative;
            }

            .name {
                order: -10;
                max-width: 50%;
                &.cell {
                    &::before {
                        display: none;
                    }
                    font-size: 16px;
                }
            }

            .flag {
                position: unset;
                &.cell {
                    &::before {
                        display: none;
                    }
                    .tag {
                        left: auto;
                        left: 30%;
                    }
                }
            }

            .ins-link,
            .remark {
                order: -9;
                &.cell {
                    max-width: 25%;
                    justify-content: flex-end;
                    &::before {
                        display: none;
                    }
                }
            }

            .address {
                &.cell {
                    justify-content: space-between;
                    .flex {
                        max-width: calc(100% - 50px);
                        align-items: flex-start;
                        p {
                            order: -1;
                            text-decoration: underline;
                            line-height: 25px;
                        }
                        .address-normal-icon,
                        .address-hover-icon {
                            margin-right: 0px;
                            margin-left: 3px;
                        }
                    }

                    &::before {
                        max-width: 30px;
                        justify-self: flex-start;
                    }
                }
            }

            .phone {
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                    .phone-content {
                        a.phone {
                            color: $sixth-black;
                            display: flex;
                            justify-content: flex-end;
                            text-decoration: underline;
                            &:nth-child(2) {
                                margin-top: 10px;
                            }
                            .phone-icon {
                                display: block;
                                margin-left: 3px;
                                height: 20px;
                                width: 20px;
                            }
                        }
                    }
                }
            }
            .supplier-info {
                order: 1;
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                    align-items: center;
                }
            }
        }
    }
}
//手機版卡片隱藏表格全螢幕功能
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}
:deep(p) {
    display: flex;
    color: $secondary-grey;
    font-size: 12px;
    margin: 0;
    padding: 0;
    span {
        display: inline-block;
    }
}

.modal {
    :deep(.modal-background) {
        .modal-title {
            padding-bottom: 30px;
        }
        .modal-content {
            textarea {
                box-sizing: border-box;
                padding: 10px;
                resize: none;
                width: 100%;
                height: 150px;
                border-radius: 3px;
                border-color: $fifth-black;
                &:focus {
                    border: 1px solid $third-blue;
                    outline: none;
                }
            }
            textarea,
            div {
                font-size: 14px;
                color: $sixth-black;
                font-family: $primary-cn;
            }
        }
        .modal-bottom {
            padding-top: 50px;
        }

        @media (max-width: 576px) {
            .modal-title {
                padding-bottom: 10px;
            }
            .modal-bottom {
                padding-top: 20px;
            }
        }
    }
}
</style>
